<template>
  <div class="homepageManagement">
    <a-spin :spinning="spinning">

    <a-button type="primary" @click="() => addShow = true">新增</a-button>
    <a-table          :rowKey="record=>record.id"
                      :columns="tableColumns"
                      :data-source="tableData"
                      :pagination="false"
                      style="margin-top: 20px"
    >
      <template slot="icon" slot-scope="text, row, index">
        <img :src="row.icon" style="width: 50px;height: 50px">
      </template>
      <template slot="operate" slot-scope="text, row, index">
        <a style="margin-right: 5px" @click="editOpen(row)">修改</a>
        <a-popconfirm
            title="是否确定删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="remove(row.id)"
        >
          <a>删除</a>
        </a-popconfirm>
      </template>
    </a-table>
    </a-spin>

    <a-modal @ok="save" v-model="addShow" title="新增">
      <a-form-model :rules="rules" :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addRef">
        <a-form-model-item label="图标" prop="icon">
          <div style="display: flex;    flex-direction: column;">
            <a-upload
                name="file"
                list-type="picture-card"
                :show-upload-list="false"
                :action="header + '/api/admin/common/update'"
                @change="handleChange($event, 'addForm')"
            >
              <img style="width: 80px;height: 80px" v-if="addForm.icon" :src="addForm.icon" alt="avatar" />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
            <span style="font-size: 11px;margin-top: -16px">注：图片大小不超过500kb</span>
          </div>
        </a-form-model-item>
        <a-form-model-item label="名称" prop="name">
          <a-input v-model="addForm.name" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="跳转路径" prop="url">
          <a-input v-model="addForm.url" placeholder="请输入"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal @ok="editSave" v-model="editShow" title="编辑">
      <a-form-model :rules="rules" :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editRef">
        <a-form-model-item label="图标" prop="icon">
          <div style="display: flex;    flex-direction: column;">
            <a-upload
                name="file"
                list-type="picture-card"
                :show-upload-list="false"
                :action="header + '/api/admin/common/update'"
                @change="handleChange($event, 'editForm')"
            >
              <img style="width: 80px;height: 80px" v-if="editForm.icon" :src="editForm.icon" alt="avatar" />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
            <span style="font-size: 11px;margin-top: -16px">注：图片大小不超过500kb</span>
          </div>
        </a-form-model-item>
        <a-form-model-item label="名称" prop="name">
          <a-input v-model="editForm.name" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="跳转路径" prop="url">
          <a-input v-model="editForm.url" placeholder="请输入"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import api_header_config from "@/service/api_header_config";
import {delHomeMode, getModeSelectAll, postHomeModeAdd, putHomeModeEdit} from "@/service/titleExam_api";
export default {
  name: "HomepageManagement",
  data () {
    return {
      spinning: false,
      editShow: false,
      addShow: false,
      editForm: {},
      addForm: {},
      editImageUrl: '',
      addImageUrl: '',
      header: api_header_config.api_header,
      loading: false,
      labelCol: { span: 5},
      wrapperCol: { span: 16 },
      rules: {
        icon:[{ required: true, message: '请上传', trigger: 'blur' }],
        name:[{ required: true, message: '请输入', trigger: 'blur' }],
        url:[{ required: true, message: '请输入', trigger: 'blur' }],
      },
      tableColumns: [
        {
          title: '图标',
          scopedSlots: { customRender: 'icon' }
        },
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '跳转路径',
          dataIndex: 'url'
        },
        {
          title: '创建时间',
          dataIndex: 'createdTime'
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' }
        }
      ],
      tableData: [],

    }//dataReturn
  },
  created() {
    this.getTableData()
  },
  methods: {
    editOpen (row) {
      this.editForm = {...row}
      this.editShow = true
    },
    async editSave () {
      if (!this.editForm.icon || !this.editForm.url || !this.editForm.icon) {
        this.$refs.editRef.validate()
        return
      }
      this.spinning = true
      const data = {
        ...this.editForm
      }
      delete data.createdBy
      delete data.createdTime
      const res = await putHomeModeEdit(data)
      if (res.code === 200) {
        await this.getTableData()
        this.$message.success('编辑成功')
        this.editShow = false
      } else {
        this.$message.warning(res.message)
      }
    },
    async save () {
      if (!this.addForm.icon || !this.addForm.url || !this.addForm.icon) {
        this.$refs.addRef.validate()
        return
      }
      this.$refs.addRef.clearValidate()
      this.spinning = true
      const data = {
        ...this.addForm
      }
      const res = await postHomeModeAdd(data)
      if (res.code === 200) {
        await this.getTableData()
        this.$message.success('新增成功')
        this.$refs.addRef.resetFields()
        this.addShow = false
      } else {
        this.$message.warning(res.message)
      }
    },
    async remove (id) {
      this.spinning = true
      const res = await delHomeMode(id)
      if (res.code === 200) {
        this.$message.success('删除成功')
        await this.getTableData()
      } else {
        this.$message.warning(res.message)
      }
    },
    async getTableData () {
      this.spinning = true
      const res = await getModeSelectAll()
      if (res.code === 200) {
        this.tableData = res.data
        this.spinning = false
      }
    },
    handleChange (e, type) {
      console.log(e)
      if (e.file.status !== 'done') {
        this.loading = true
        return
      }
      if (e.file.size > 512000) {
        this.loading = false
        this.$message.warning('请上传小于500kb的图片')
        return;
      }
      if (e.file.response.code === 0) {
        this[type].icon = e.file.response.data.url
        this.loading = false
        this.$message.success('上传成功')
      }
    }
  }
}
</script>

<style scoped>
.homepageManagement {
  padding: 10px;
}
</style>